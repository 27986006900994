.table {
    width: 100% !important;

    th {
        border-bottom-width: 3px;
        font-family: $font-family-medium;
    }

    th,
    td {
        padding-right: .5rem;
    }

    & > :not(:last-child) > :last-child > * {
        border-color: $gray-100;
    }
}

.dataTables_filter label {
    display: flex;
    width: auto;
    align-items: baseline;

    input {
        margin-left: .5rem;
    }
}

.dataTables_length {
    margin-top: 1rem;
}

table.dataTable > thead .sorting::after,
table.dataTable > thead .sorting::before,
table.dataTable > thead .sorting_asc::after,
table.dataTable > thead .sorting_asc::before,
table.dataTable > thead .sorting_asc_disabled::after,
table.dataTable > thead .sorting_asc_disabled::before,
table.dataTable > thead .sorting_desc::after,
table.dataTable > thead .sorting_desc::before,
table.dataTable > thead .sorting_desc_disabled::after,
table.dataTable > thead .sorting_desc_disabled::before {
    bottom: 1.1em;
}

.dtr-details {
    list-style: none;
    padding: 0;
    margin: 0;

    .dtr-title {
        font-family: $font-family-medium;

        &::after {
            content: ":";
        }
    }
}
