.main-navbar {
    transition: margin-left .3s ease-in-out;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    @include media-breakpoint-up(lg) {
        margin-left: $main-sidebar-width;
    }

    .main-navbar-header {
        border-top: 1px solid $gray-300;
        background-color: $gray-200;
        padding: .5rem 1rem;
        min-height: px-rem(55);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h1 {
            font-size: px-rem(22);
            line-height: 1;
            margin: 0;
        }

        .progress {
            position: absolute;
            left: 0;
            bottom: -.25rem;
            height: .25rem;
            background: transparent;
            width: 100%;
        }
    }

    .navbar-dropdown {
        .dropdown-toggle {
            color: $navbar-light-color;
            text-decoration: none;
            transition: $nav-link-transition;

            &:hover,
            &:focus {
                color: $navbar-light-hover-color;
            }
        }

        .dropdown-menu {
            min-width: 3rem;
        }
    }

    .quick-links-dropdown {
        .dropdown-menu {
            min-width: 25rem;

            &.show {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

            .quick-link {
                display: inline-block;
                background-color: $gray-200;
                border-left: 5px solid $gray-300;
                color: $gray-500;
                font-family: $font-family-medium;
                width: 31%;
                margin-bottom: 3%;
                margin-right: 2.3%;

                &:hover,
                &:focus {
                    background-color: $gray-500;
                    border-color: #1C64C4;
                    color: $white;
                    text-decoration: none;
                }

                .ti {
                    font-size: px-rem(32);
                    display: block;
                    margin-bottom: .5rem;
                }
            }
        }
    }

    .search {
        .btn {
            border-color: $gray-300;
        }
    }
}

.login {
    .navbar {
        height: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 30px;
        box-shadow: none;

        @include media-breakpoint-up(lg) {
            height: 120px;
            background-size: auto 60px;
        }

        .btn {
            color: $navbar-light-color;
            transition: $nav-link-transition;

            &:hover,
            &:focus {
                color: $navbar-light-hover-color;
            }
        }

        .collapsing {
            transition: none;
        }
    }
}
