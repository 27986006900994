.modal {
    .modal-header {
        padding: 0;
        padding-left: 1rem;
    }

    .modal-header .btn-close-modal {
        margin: 0;
        padding: 1rem;
        background-color: $gray-500;
        color: $white;
        line-height: 1;

        .ti {
            font-size: px-rem(24);
        }
    }

    .modal-content {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    }
}
