.btn-light {
    border-color: $gray-300;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: $white;
}

.btn-catalog {
    padding-top: 0;
    padding-bottom: 0;
    background-color: $white;
    border-color: $gray-300;
    border-left-color: transparent;

    .ti {
        font-size: px-rem(17);
        line-height: 1.5;
    }
}
