.content-card {
    background-color: $white;
    border-bottom: 1px solid $gray-500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .25);
    height: 100%;

    .content-card-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: .75rem 1rem;
        min-height: px-rem(61);

        .nav-tabs,
        .scrtabs-tab-container {
            margin-bottom: -.75rem;
        }
    }

    .content-card-title {
        font-family: $font-family-medium;
        font-size: px-rem(15);
        line-height: 1;
        margin: 0;
        display: inline-block;
    }
}
