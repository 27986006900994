/* Roboto */
@import "~fontsource-roboto/latin-ext-300.css";
@import "~fontsource-roboto/latin-ext-400.css";
@import "~fontsource-roboto/latin-ext-500.css";

/* Themify Icons */
@import "~@icon/themify-icons/themify-icons.css";

.ti {
  line-height: 1;
  margin-right: 4px;
}
