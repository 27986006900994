/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

:root {
  --main-bg-color-calendar: #fff;
  --main-color-calendar: #4a89dc;
  --main-border-color-calendar: #4a89dc;

  --hovact-bg-color-calendar: #4a89dc;
  --hovact-color-calendar: #fff;
  --hovact-border-color-calendar: #4a89dc;

  --main-bg-color-matrix: white;
  --main-color-matrix: black;
  --main-border-color-matrix: grey;
  --active-bg-color-matrix: brown;
  --active-color-matrix: black;
}

.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 15px;
}

.ngx-datatable.bootstrap .datatable-header {
  height: unset !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.ngx-datatable.bootstrap .datatable-row-wrapper:first-child .datatable-body-row {
  border-top: 2px solid #d1d4d7;
}

.ngx-datatable.bootstrap .datatable-body {
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;

}


.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 10px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.02);
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  vertical-align: top;
}

.ngx-datatable.bootstrap .datatable-body .datatable-row-wrapper:first-child .datatable-body-row.datatable-row-even:hover {
  border-top: 2px solid #d1d4d7;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even:hover,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row:hover {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
  background-color: lightgray;
  cursor: pointer;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even.no-activator:hover,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.no-activator:hover {
  cursor: default;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: black;
}

.ngx-datatable.bootstrap .datatable-body .empty-row {
  padding-left: 12px;
}

.ngx-datatable.bootstrap .datatable-footer {
  background: #424242;
  color: #ededed;
  margin-top: -1px;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: bold;


}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell.longpress .draggable:after {
  transition: transform .4s ease, opacity .4s ease;
  opacity: .5;
  transform: scale(1);
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .draggable:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  text-align: start;
  font-weight: bold;
  background-color: #fff;
  vertical-align: bottom;
}

.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: scroll !important;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-left: none;
  border-color: #e1e0de;
}

.hidden {
  display: none;
}

.afu-reset-btn,
.afu-select-btn {
  color: #4a89dc;
  background-color: #fff;
  border-color: #4a89dc;
}

.afu-reset-btn:focus,
.afu-reset-btn:active,
.afu-reset-btn:hover,
.afu-select-btn:focus,
.afu-select-btn:active,
.afu-select-btn:hover {
  color: #fff;
  background-color: #4a89dc;
  border-color: #4a89dc;
  box-shadow: none;
}

.afu-reset-btn:disabled,
.afu-reset-btn.disabled,
.afu-select-btn:disabled,
.afu-select-btn.disabled {
  color: #4a89dc;
  background-color: transparent;
  border-color: #4a89dc;
}

.afu-upload-btn {
  color: rgb(var(--bs-dark-rgb));
  background-color: #fff;
  border-color: rgb(var(--bs-dark-rgb));;
}

.afu-upload-btn:focus,
.afu-upload-btn:active,
.afu-upload-btn:hover {
  color: #fff;
  background-color: rgb(var(--bs-dark-rgb));
  border-color: rgb(var(--bs-dark-rgb));
  box-shadow: none;
}

.afu-upload-btn:disabled,
.afu-upload-btn.disabled {
  color: rgb(var(--bs-dark-rgb));
  background-color: transparent;
  border-color: rgb(var(--bs-dark-rgb));
}

.afu-valid-file .text-primary {
  color: rgb(var(--bs-dark-rgb)) !important;
}

.afu-upload-status {
  color: rgb(var(--bs-dark-rgb)) !important;
}

.afu-dragndrop-box {
  width: auto !important;
}

.content-wrapper.recurrence-editor-wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.recurrenceOptionsWrapper {
  padding-bottom: 1rem;
}

.endOptionsWrapper {
  display: none;
  padding: 0 0 8px 0;
}

.recurrenceOptions .btn.btn-outline-secondary,
.endOptions .btn.btn-outline-secondary {
  margin-right: 3px;
  margin-bottom: 3px;
}

.e-recurrenceeditor .e-input-wrapper-side.e-end-on .e-end-on-left,
.e-end-on-label {
  display: none;
}

.e-recurrenceeditor .e-month-expander-checkbox-wrapper.e-input-wrapper .e-month-type .e-label {
  display: block;
}

.e-recurrenceeditor .e-month-expander-checkbox-wrapper.e-input-wrapper {
  margin-bottom: 0px !important;
}

.e-input-group input.e-input,
.e-float-input.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-float-input.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-control-wrapper input {
  margin-left: 8px;
}

.e-recurrenceeditor .e-input-wrapper.e-form-left {
  padding: 0 8px 16px 0;
  display: none;
}

.e-recurrenceeditor .e-editor {
  display: grid;
  margin-right: unset;
  margin-left: unset;
}

.e-recurrenceeditor .e-input-wrapper-side.e-form-left,
.e-recurrenceeditor .e-form-right,
.e-recurrenceeditor .e-input-wrapper-side.e-form-right {
  padding: 0px;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: var(--main-color-calendar);
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: var(--main-color-calendar) !important;
}

.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
.e-float-input .e-control-wrapper:not(.e-error) input label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[disabled] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text {
  color: #4a4a4a;
}

.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
  border-width: 1px 1px 1px 1px;
  border-color: #4a89dc !important;
}

.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.is-invalid.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error input,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error) textarea,
.e-float-input.e-input-focus:not(.e-input-group.is-invalid):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error textarea,
.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.is-invalid.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.is-invalid.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.is-invalid.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
.e-input-group.is-invalid.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.is-invalid.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.is-invalid.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
.e-input-group.is-invalid.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}

.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) input[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input[readonly] ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly]:focus ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea[readonly]:focus ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus textarea[readonly] ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus input ~ label.e-float-text {
  color: #4a4a4a;
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: #3390ff;
  color: #fff;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
.e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
  background: none;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: none;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
  border: 1px solid;
  border-color: #e1e0de !important;
}

.e-recurrence-table.e-month-expand-wrapper tr,
.e-recurrence-table.e-month-expand-wrapper tr td:nth-last-child(1),
.e-recurrence-table.e-month-expand-wrapper tr td:nth-last-child(2) {
  display: block !important;
  clear: both;
}

.e-recurrenceeditor .e-recurrence-table.e-repeat-content-wrapper td:last-child {
  text-align: end;
}

.e-recurrenceeditor .e-input-wrapper.e-end-on-count,
.e-recurrenceeditor .e-input-wrapper.e-end-on-date {
  width: 100% !important;
}

.e-recurrence-table.e-month-expand-wrapper tr,
.e-recurrence-table.e-month-expand-wrapper tr td {
  clear: both;
}

.e-recurrenceeditor .e-recurrence-table .e-week-position {
  right: 0px;
}

.e-recurrenceeditor .e-input-wrapper-side.e-non-week > .e-month-expander-label {
  margin-bottom: 8px !important;
}

.e-recurrenceeditor .e-recurrence-table .e-monthday-element {
  padding-left: 0px;
}

.e-recurrenceeditor .e-month-expander-checkbox-wrapper.e-input-wrapper .e-month-type .e-label {
  display: block;
}

.e-recurrenceeditor .e-input-wrapper div {
  margin-bottom: 0;
}

.e-recurrenceeditor .e-days button {
  border-radius: 0;
}

.e-btn.e-round,
.e-btn.e-round:focus,
.e-btn.e-round:active {
  color: #4a89dc;
  border-color: #4a89dc;
  background-color: #fff;
  box-shadow: none;
}

.e-btn.e-round .e-close-icon {
  color: #000;
}

.e-btn.e-primary,
.e-btn.e-primary:focus,
.e-btn.e-primary:hover,
.e-btn.e-primary.e-active,
.e-btn.e-round:hover,
.e-btn.e-round:hover .e-close-icon {
  color: #fff;
  border-color: #4a89dc;
  background-color: #4a89dc;
  box-shadow: none;
}

label.e-float-text,
.e-recurrenceeditor .e-days .e-week-expander-label,
.endOptionsWrapper .form-label,
.e-recurrenceeditor .e-input-wrapper-side.e-non-week > .e-month-expander-label,
.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text {
  font-family: "Roboto Medium", sans-serif;
  font-size: 0.9375rem;
}

.e-float-input.e-control-wrapper:not(.e-error) input:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input ~ label.e-label-top.e-float-text {
  top: -20px;
  transform: unset;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
  color: var(--main-color-calendar);
}

.e-radio:checked + label::after,
.e-radio:focus + label::after,
.e-radio + label:hover::after {
  background-color: var(--main-color-calendar) !important;
  color: var(--main-color-calendar) !important;
}

.e-radio:checked + label::before,
.e-radio:checked + label:hover::before,
.e-radio:checked:focus + label::before {
  border-color: var(--main-color-calendar);
}

.ti.ti-close {
  margin-right: unset !important;
}

.e-clear-icon {
  display: none !important;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: var(--main-color-calendar);
}

.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: var(--main-color-calendar);
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: var(--main-color-calendar);
}

.orientationcss .e-headercelldiv {
  transform: rotate(90deg);
}

.e-grid.e-responsive .orientationcss .e-headercelldiv {
  text-overflow: unset;
}

app-frozen-grid .e-grid.e-responsive .orientationcss .e-headercelldiv {
  width: 150px;
  margin-left: -70px;
  text-overflow: ellipsis;
}

.e-grid .orientationcss .e-headercelldiv span {
  overflow: unset;
}

.e-grid .orientationcss .e-headercelldiv span.e-sortnumber {
  transform: rotate(-90deg);
  margin-top: -7px;
  margin-right: -26px;
}

.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary:active,
.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus,
.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  color: var(--hovact-color-calendar);
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-calendar .e-content td.e-selected span.e-day {
  background-color: var(--hovact-bg-color-calendar);
}

.e-calendar .e-content td.e-selected:hover span.e-day {
  background-color: var(--hovact-bg-color-calendar);
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  color: var(--main-color-calendar);
  background-color: var(--main-bg-color-calendar);
  border-color: var(--main-border-color-calendar);
}

.e-calendar .e-content td.e-today span.e-day {
  border-color: var(--main-border-color-calendar);
  color: var(--main-color-calendar);
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day {
  color: var(--hovact-color-calendar);
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day {
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  color: var(--hovact-color-calendar);
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  color: var(--hovact-color-calendar);
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  color: var(--hovact-color-calendar);
  background-color: var(--hovact-bg-color-calendar);
  border-color: var(--hovact-border-color-calendar);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  color: var(--main-color-calendar);
}

.e-input-group .e-input-group-icon:last-child,
.e-input-group.e-bigger .e-input-group-icon:last-child,
.e-input-group .e-input-group-icon.e-bigger:last-child,
.e-bigger .e-input-group .e-input-group-icon:last-child,
.e-input-group.e-small .e-input-group-icon:last-child,
.e-input-group.e-small.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-small .e-input-group-icon.e-bigger:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper .e-input-group-icon.e-bigger:last-child,
.e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-small.e-bigger .e-input-group-icon:last-child,
.e-input-group.e-control-wrapper.e-small .e-input-group-icon.e-bigger:last-child,
.e-bigger .e-input-group.e-control-wrapper.e-small .e-input-group-icon:last-child,
.e-bigger .e-input-group.e-small .e-input-group-icon:last-child {
  padding-right: 0.75rem;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left),
.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
.e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border-width: 1px 1px 1px 1px;
}

.e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
.e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left).e-error:not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left.e-error:not(.e-float-input) .e-input-in-wrap::after {
  background: none;
}

.e-input-group.e-error,
.e-input-group.e-control-wrapper.e-error,
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border-color: #e1e0de;
}

.bs-stepper-content {
  overflow: auto;
}

.bs-stepper app-button-bar {
  display: none;
}

.bs-stepper-content app-button-bar {
  display: block;
}

.bs-stepper-content app-button-bar div {
  padding-top: 10px;
}

.navbar {
  display: block;
}

app-charts .content-card {
  height: unset !important;
}

.chart-legend ul {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0rem;
  /* max-height: 68px; */
  padding-left: 0;
}

.chart-legend li {
  padding-right: 5px;
  display: flex;
  list-style: none;
  width: max-content;
  cursor: pointer;
}

.chart-legend li.strike {
  text-decoration: line-through;
}

.chart-legend li span {
  margin-top: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 15px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border: 2px solid #fff;
}


app-input-ref .e-dlg-content app-context-tree ul:first-of-type {
  padding-left: 6px !important;
}

app-input-ref input.is-invalid {
  border-right: 1px solid #dc3545;
}

app-input-ref input.is-invalid:focus {
  border-right: 1px solid #dc3545;
}

app-input-ref input.is-valid {
  border-right: 1px solid #198754;
}

app-input-ref input.is-valid:focus {
  border-right: 1px solid #198754;
}

app-textarea textarea.is-valid,
app-textarea textarea.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right calc(1.375em + 0.1875rem)
}

app-combobox select.is-valid,
app-combobox select.is-invalid {
  background-position: right calc(2.375em + 0.1875rem) center !important;
}

app-date-time-picker span.e-input-group.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

app-date-time-picker span.e-input-group.is-valid {
  border-color: #198754 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

app-detail-search .row div app-input-dynamic > div {
  margin-bottom: 1rem;
}

app-tiles-bar {
  margin-top: 0px !important;
}

.e-recurrenceeditor .e-days button {
  margin: 0 8px 8px 0px;
}

.e-control,
.e-css,
input.e-input,
textarea.e-input,
.e-input-group,
.e-input-group.e-control-wrapper {
  font-family: var(--bs-font-sans-serif);
  font-size: 0.9375rem;
}

app-form-step app-checkbox .form-check {
  margin-bottom: 6.75px;
}

app-input-dynamic label {
  font-weight: bold;
}

app-input-dynamic app-checkbox label {
  font-weight: normal;
}

.form-control:focus {
  border-color: #4a89dc;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  z-index: 6 !important;
}

.form-check-input:focus,
.form-select:focus {
  border-color: #4a89dc;
}

.form-check-input:checked {
  background-color: #4a89dc;
  border-color: #4a89dc;
}

app-tabs .scrollbar-wrapper .content-card-header app-button-bar .d-flex {
  padding-right: 20px;
}

app-tabs .scrollbar-wrapper .content-card-header app-button-bar .d-flex button,
app-tabs #mobile-button-bar app-button-bar .d-flex button,
app-toolbar #mobile-button-bar app-button-bar .d-flex button,
app-toolbar app-entity-action-toolbar .d-flex button {
  white-space: nowrap;
  margin-right: 5px;
}

app-tabs .scrollbar-wrapper .content-card-header app-button-bar .dropdown {
  position: static;
  margin-right: 5px;
}

/* Context-Tree sichtbar + Sidebar aufgeklappt */
body :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu::before {
  width: calc(100% - 272px - 256px);
}

/* Sidebar zugeklappt */
body.main-sidebar-collapsed .scrollbar-wrapper.n-quickmenu::before {
  width: calc(100% - 92px);
}

/* Context-Tree sichtbar + Sidebar zugeklappt */
body.main-sidebar-collapsed :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu::before {
  width: calc(100% - 92px - 256px);
}

.main-sidebar .main-sidebar-nav a .fa {
  display: inline-block;
  width: 2rem;
  text-align: center;
}

.btn.main-sidebar-toggler.main-sidebar-toggler-mobile:hover,
.btn.main-sidebar-toggler.main-sidebar-toggler-mobile:focus {
  border-color: transparent;
}

#quickMenu .scrollbar-wrapper::before {
  width: auto;
}

/* sidebar aufgeklappt + Quickmenu*/
body.quick-menu-open .scrollbar-wrapper.n-quickmenu::before {
  width: calc(75% - 272px + 64px);
}

body.quick-menu-open .scrollbar-wrapper.n-quickmenu .ti-angle-right {
  right: calc(25% - 52px);
}

/* sidebar aufgeklappt + QuickmenuLarge*/
body.quick-menu-open .scrollbar-wrapper.n-quickmenu.large::before {
  width: calc(50% - 272px + 128px);
}

body.quick-menu-open .scrollbar-wrapper.n-quickmenu.large .ti-angle-right {
  right: calc(50% - 117px);
}

/* Context-Tree sichtbar + Sidebar aufgeklappt + Quickmenu */
body.quick-menu-open :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu::before {
  width: calc(75% - 272px - 192px);
}

/* Context-Tree sichtbar + Sidebar aufgeklappt + QuickmenuLarge */
body.quick-menu-open :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu.large::before {
  width: calc(50% - 272px - 128px);
}

/* Sidebar zugeklappt + Quickmenu */
body.main-sidebar-collapsed.quick-menu-open .scrollbar-wrapper.n-quickmenu::before {
  width: calc(75% - 73px);
}

body.main-sidebar-collapsed.quick-menu-open .scrollbar-wrapper.n-quickmenu .ti-angle-right {
  right: calc(25% - 7px);
}

/* Sidebar zugeklappt + QuickmenuLarge */
body.main-sidebar-collapsed.quick-menu-open .scrollbar-wrapper.n-quickmenu.large::before {
  width: calc(50% - 54px);
}

body.main-sidebar-collapsed.quick-menu-open .scrollbar-wrapper.n-quickmenu.large .ti-angle-right {
  right: calc(50% - 26px);
}

/* Context-Tree sichtbar + Sidebar zugeklappt + Quickmenu  */
body.main-sidebar-collapsed.quick-menu-open :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu::before {
  width: calc(75% - 92px - 237px);
}

/* Context-Tree sichtbar + Sidebar zugeklappt + QuickmenuLarge  */
body.main-sidebar-collapsed.quick-menu-open :not(.context-tree-collapsed) ~ #detail .scrollbar-wrapper.n-quickmenu.large::before {
  width: calc(50% - 92px - 218px);
}

@media (max-width: 991px) {
  body.quick-menu-open #detail {
    display: none;
  }
}

#quickMenu .content-card .content-card-header .content-card {
  border-bottom: none;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  justify-content: flex-end;
}

.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-item-count {
  padding-top: 10px;
}

app-kanban .e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-item-count {
  padding: 2px;
}

#_dialog_wrapper {
  width: max-content !important;
}

#_dialog_wrapper .e-float-input input,
#_dialog_wrapper .e-float-input textarea,
#_dialog_wrapper .e-float-input.e-control-wrapper input,
#_dialog_wrapper .e-float-input.e-control-wrapper textarea {
  border: 1px solid;
  border-color: #e1e0de;
  font-family: var(--bs-font-sans-serif);
  font-size: 15px;
  margin-left: 0px;
  padding-left: 8px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

#_dialog_wrapper .e-float-input .e-datepicker .e-input {
  border: unset;
}

#_dialog_wrapper .e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: var(--main-border-color-calendar) !important;
}

#_dialog_wrapper .e-dlg-content {
  max-height: 400px;
}

.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-text {
  margin-top: 7px;
}

#_dialog_wrapper .e-dlg-header-content {
  border-bottom: 1px solid #e1e0de;
  display: flex;
  flex-shrink: 0;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e0de;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0px;
  padding-left: 1rem;
}

#_dialog_wrapper .e-dlg-content .e-kanban-form-wrapper table {
  margin-top: -18px;
}

#_dialog_wrapper .e-icon-dlg-close {
  font-size: 1.5rem;
  color: #fff;
}

#_dialog_wrapper .e-footer-content {
  border-top: 1px solid #e1e0de;
}

ejs-dialog {
  max-width: 1000px !important;
  margin: 1.75rem;
}

.e-dialog {
  max-height: 90% !important
}

.e-dialog .e-dlg-header {
  font-size: 1.171875rem;
}

.e-dialog .e-dlg-content .form-check .form-check-label {
  margin-top: 2px;
}

.e-dialog .table thead tr th {
  border-color: unset;
  border-bottom-width: 0px;
}

.e-dlg-header-content {
  border-bottom: 1px solid #e1e0de !important;
  display: flex;
  flex-shrink: 0;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0px !important;
  padding-left: 1rem !important;
}

.e-dlg-header-content .e-dlg-closeicon-btn {
  margin: 0;
  padding: 1rem;
  background-color: #a2a2a2 !important;
  line-height: 1;
  border-radius: unset !important;
  height: unset !important;
  left: unset !important;
  width: unset !important;
}

.e-dialog .e-dlg-header-content + .e-dlg-content {
  padding-top: 18px;
  font-size: 0.9375rem;
}

.e-icon-dlg-close,
.e-icon-dlg-close:hover {
  font-size: 1.5rem !important;
  color: #fff !important;
}

.e-dialog .e-icon-dlg-close::before {
  content: '\ea7f' !important;
}

.e-footer-content {
  border-top: 1px solid #e1e0de !important;
  padding-left: 1.5rem !important;
  text-align: left !important;
}

.e-btn {
  text-transform: unset;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.9375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.e-icon-schedule-filterFields:before {
  content: "\e434";
}

app-view .main-navbar-h1,
app-wizard .main-navbar-h1 {
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.375rem;
  line-height: 1.7;
  margin: 0;
}

app-view .main-navbar-h1 button,
app-wizard .main-navbar-h1 button {
  line-height: 1;
  padding: 10px;
}

.main-navbar-h1 {
  text-overflow: ellipsis;
}


/* extra Klasse zum drehen des Textes in entity-Matrix-Header */
.e-grid .e-headercell.orientationCSS div {
  writing-mode: vertical-rl;
  margin: 0;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: unset;
}


.e-splitter .e-split-bar.e-split-bar-horizontal {
  background-color: var(--main-border-color-matrix);
  color: var(--main-border-color-matrix);
}

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
  background-color: var(--main-border-color-matrix);
  color: var(--main-color-matrix);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
  background-color: var(--main-color-matrix);
  color: var(--main-color-matrix);
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-resize-handler,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
  background-color: var(--main-color-matrix);
  color: var(--main-border-color-matrix);
}

/* Editor-Window Scheduler */
.e-dlg-content .e-form-container .e-schedule-form .e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input:not(.e-input-group) .e-float-line::before,
.e-float-input:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::before,
.e-float-input.e-control-wrapper:not(.e-input-group) .e-float-line::after {
  background: none;
}

.e-dlg-content .e-form-container .e-schedule-form .e-input-group input.e-input,
.e-float-input.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-float-input.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-control-wrapper input {
  border: none !important;
}

.e-dlg-content .e-form-container .e-schedule-form .e-input-group input.e-input.e-subject,
.e-float-input.e-input-group input.e-subject,
.e-input-group.e-control-wrapper input.e-input.e-subject,
.e-float-input.e-input-group.e-control-wrapper input.e-subject,
.e-float-input input.e-subject,
.e-float-input.e-control-wrapper input.e-subject {
  border: 1px solid !important;
  border-color: #e1e0de !important;
}

.e-dlg-content .e-form-container .e-schedule-form .e-input-group input.e-input.e-location,
.e-float-input.e-input-group input.e-location,
.e-input-group.e-control-wrapper input.e-input.e-location,
.e-float-input.e-input-group.e-control-wrapper input.e-location,
.e-float-input input.e-location,
.e-float-input.e-control-wrapper input.e-location {
  border: 1px solid !important;
  border-color: #e1e0de !important;
}

.e-dlg-content .e-form-container .e-schedule-form .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) textarea label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea:valid ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea:valid ~ label.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[readonly] ~ label.e-label-top.e-float-text,
.e-bigger .e-float-input.e-control-wrapper:not(.e-error) textarea[disabled] ~ label.e-label-top.e-float-text {
  top: -20px;
  transform: unset;
  font-family: "Roboto Medium", sans-serif;
  font-size: 0.9375rem;
  color: #4a4a4a;
}

.status-bar {
  z-index: 99;
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #4a4a4a;
  height: 30px;
  display: flex;
  justify-content: end;
}

.login .status-bar {
  background-color: #f0f0f0;
}

.login .status-bar app-loader {
  width: calc(100% - 60px);
}

.invalid-feedback,
.valid-feedback {
  margin-top: 0rem;
}

.dropdown-menu.infoData.show thead,
.dropdown-menu.infoData.show tbody,
.dropdown-menu.infoData.show tr,
.dropdown-menu.infoData.show th,
.dropdown-menu.infoData.show td {
  border-width: 1px;
  border-color: #e1e0de;
}

.dropdown-menu.infoData.show thead tr th {
  font-weight: 600;
}

.tooltip {
  pointer-events: none;
}

.customInfoTooltip {
  position: relative;
  font-size: 0px;
  margin-left: 5px;
  padding: 1px;
  background-color: #ffffff;
  border-radius: 100%;
  border: 1px solid;
  color: #4a4a4a;
  cursor: help !important;
  transition: all 0.2s ease-out;
}

.customInfoTooltip:focus {
  outline: none;
}

.customInfoTooltip .ti,
.customInfoTooltip .fa {
  margin-right: 0px;
  font-size: 12px;
}

body:not(.dashboard) #chartjs-tooltip {
  opacity: 0 !important;
  display: none;
}

ejs-toast {
  z-index: 1051 !important;
}

.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
  vertical-align: baseline !important;
}

/* Dashboard-Widget drucken */
.dashboard-widget.active-print .content-card {
  overflow: unset !important;
  height: auto !important;
}

.dashboard-widget.active-print .content-card .content-card-content-primary {
  overflow: hidden;
}

.dashboard-widget.active-print .flex-item {
  height: auto !important;
  break-inside: avoid;
}

@media (max-width: 1265px) {
  body :not(.context-tree-collapsed) ~ #detail app-image-upload .sample_wrapper .dropArea_wrap {
    width: 63.3333333333%;
  }

  body :not(.context-tree-collapsed) ~ #detail app-image-upload .sample_wrapper .uploaderWrapper {
    width: 36.6666666667%;
  }
}

app-tabs ~ #tabsContent,
.col.context-tree app-context-tree ejs-treeview {
  max-height: calc(100vh - 14.4rem);
  overflow: auto;
}

app-widget {
  margin-top: 0px !important;
}

@media (max-width: 991px) {
  .e-recurrenceeditor .e-input-wrapper {
    width: 100%;
  }

  .e-recurrenceeditor .e-input-wrapper-side {
    width: 100%;
  }

  body.main-sidebar-open app-pit .main-navbar-header {
    display: none;
  }

  app-tabs ~ #tabsContent {
    max-height: calc(100vh - 19.4rem);
    overflow-y: auto;
  }

  app-tabs.empty ~ #tabsContent {
    max-height: calc(100vh - 15.5rem);
    overflow-y: auto;
  }

  .content {
    padding-bottom: 3rem;
  }

  app-sidebar-footer footer.footer {
    margin-bottom: 1rem;
    padding-bottom: 0rem !important;
  }

  .status-bar {
    display: block;
    height: 23px;
  }
}


@media (max-width: 767px) {
  .modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .modal-footer {
    justify-content: end;
  }

  app-toolbar .ti,
  .fa {
    margin-right: 0px !important;
    padding: 0px;
  }

  app-toolbar .mat-button {
    min-width: unset;
  }
}

@media (max-width: 580px) {

  .e-recurrenceeditor .e-editor .e-input-wrapper.e-end-on-date,
  .e-recurrenceeditor .e-editor .e-input-wrapper.e-end-on-count {
    padding-left: 0;
    padding-right: 0;
  }

  #detail app-image-upload .sample_wrapper .dropArea_wrap {
    width: 63.3333333333% !important;
  }

  #detail app-image-upload .sample_wrapper .uploaderWrapper {
    width: 36.6666666667% !important;
  }
}

@media (max-width: 520px) {
  .bs-stepper-content {
    padding: 0 20px 20px;
  }

  .modal-dialog {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 991px) {

  .dashboard-widget.active-print app-list-dashboard .table > :not(caption) > * > * {
    padding: 1rem 1rem !important;
    font-size: var(--bs-body-font-size) !important;
  }

  .dashboard-widget.active-print app-list-dashboard tr {
    display: table-row !important;
  }

  .dashboard-widget.active-print app-list-dashboard th,
  .dashboard-widget.active-print app-list-dashboard td {
    display: table-cell !important;
  }

  .dashboard-widget.active-print app-list-dashboard tbody{
    display: table-row-group !important;
  }

  .dashboard-widget.active-print app-list-dashboard thead {
    display: table-row-group !important;
  }

  .dashboard-widget.active-print app-list-dashboard table {
    display: table !important;
  }

  .dashboard-widget.active-print app-list-dashboard thead tr {
    position: unset !important;
  }

  .dashboard-widget.active-print app-list-dashboard .content-card-content-primary tr {
    white-space: normal;
  }

  .dashboard-widget.active-print app-list-dashboard thead tr:first-child {
    border-bottom: 3px solid;
    border-color: inherit;
  }

  .dashboard-widget.active-print app-list-dashboard td {
    border-bottom: 1px solid;
    border-color: inherit;
    position: initial !important;
    padding-left: 1rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .dashboard-widget.active-print app-list-dashboard .empty-row {
    pointer-events: none !important;
  }

  .dashboard-widget.active-print app-list-dashboard td.empty-row {
    padding: 0.5rem !important;
  }

  .dashboard-widget.active-print app-list-dashboard td:last-child {
    border-bottom: 0 solid inherit !important;
  }

  .dashboard-widget.active-print app-list-dashboard td:before {
    position: unset !important;
    padding-right: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: var(--bs-body-font-size) !important;
  }

  .dashboard-widget.active-print app-list-dashboard td:before {
    content: unset !important;
    font-weight: bold !important;
  }

  .dashboard-widget.active-print app-list-dashboard .resTableFooter {
    bottom: 0px !important;
  }
}

@media (min-width: 992px) {
  .status-bar app-loader {
    width: calc(100% - 15rem - 60px);
  }

  .main-sidebar-collapsed .status-bar {
    margin-left: 3.75rem;
  }

  .main-sidebar-collapsed .status-bar app-loader {
    width: calc(100% - 3.75rem - 60px);
  }

  .main-sidebar-collapsed .status-bar #reportBadge {
    margin-right: 3.75rem;
  }
}

@media (min-width: 576px) {
  app-kanban .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
  }
}


.disabled {
  opacity: 0;

}

.required {
  border-style: solid;
  border-width: 1px;
  border-color: red;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.printable {
  display: none;
}


@media print {
  * {

    visibility: hidden;
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
    visibility: visible;
    display: block;
  }

  app-widget *  {
    left: 0;
    top: 0;
    visibility: visible;
  }


  #svg-viewer  {
    margin: 0;
    padding: 0;
    visibility: visible;
    overflow: visible;
  }



  app-svg-viewer {

    margin: 0;
    padding: 0;
    top: 20px;
    position: absolute;
  }

  .svgWrapper {

    visibility: visible;
    position: absolute;
    left: 0;
    top: 20px;
  }

  .svgToolbar {
    display: none;
  }
}

.dropdown-item {
  cursor: pointer;
}

.timeline {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.filter-bar-margin {
  top: 100px !important;
}
