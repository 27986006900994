@import "fonts";
@import "mixins";
@import "variables";
@import "bootstrap-variables";
@import "~bootstrap/scss/bootstrap";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~jquery-bootstrap-scrolling-tabs/dist/jquery.scrolling-tabs.css";
@import "buttons";
@import "forms";
@import "modals";
@import "main-navbar";
@import "main-sidebar";
@import "content";
@import "footer";

.font-family-regular {
    font-family: $font-family-regular;
}

.font-family-light {
    font-family: $font-family-light;
}

.font-family-medium {
    font-family: $font-family-medium;
}
