@charset "utf-8";

@mixin ti-icon($content) {
    content: unquote("\"#{ $content }\"");
    font-family: $font-family-icons;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    speak: none;
    text-decoration: none;
    text-transform: none;
}

@function px-rem($pixel, $root-size: 16) {
    @return $pixel / $root-size * 1rem;
}
