.col.context-tree {
    transition: width .3s ease-in-out;
    width: calc(var(--bs-gutter-x) + #{$context-tree-width});
    flex: 0 0 calc(var(--bs-gutter-x) + #{$context-tree-width});

    .content-card-title {
        padding-left: 0 !important;
        padding-bottom: 5px;
        font-weight: bold;
    }

    .hidden-when-collapsed {
        transition: visibility .3s ease, opacity .3s ease;
        opacity: 1;
        display: inline-block;
    }

    .context-tree-content {
        width: 100%;
        height: calc(100vh - 250px);
        overflow-y: auto;
    }

    .context-tree-list {
        list-style: none;
        padding: 0;
        margin: 0;

        ul {
            list-style: none;
            padding-left: 1rem;
            margin: 0;
        }
    }
}

.col.context-tree-collapsed {
    width: calc(var(--bs-gutter-x) + #{$context-tree-width-collapsed});
    flex: 0 0 calc(var(--bs-gutter-x) + #{$context-tree-width-collapsed});
}
