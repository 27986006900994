.footer {
    z-index: 120;
    background: $dark;
    color: $gray-500;
    font-size: px-rem(14);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
        display: block;
        background: transparent;
        width: $main-sidebar-width;
    }

    .logo {
        height: px-rem(20);
        width: auto;
    }
}

@include media-breakpoint-up(lg) {
    .main-sidebar-open {
        .footer {
            transition: visibility .3s ease-in-out;
            visibility: visible;
        }
    }

    .main-sidebar-collapsed {
        .footer {
            transition: visibility .1s ease-in-out;
            visibility: hidden;
        }
    }
}
