/* State colors */
$state-open: #ee9800;
$state-processing: #4a89dc;
$state-done: #449d44;
$state-due: #f34033;
$state-width: px-rem(5);

.text-state-open {
    color: $state-open;
}

.text-state-due {
    color: $state-due;
}

.text-state-processing {
    color: $state-processing;
}

.text-state-done {
    color: $state-done;
}

span.state {
    display: inline-block;
    width: $state-width;
    height: 2rem;
    margin: -.5rem .5rem -.5rem 0;
    vertical-align: middle;

    &.state-open {
        background-color: $state-open;
    }

    &.state-due {
        background-color: $state-due;
    }

    &.state-processing {
        background-color: $state-processing;
    }

    &.state-done {
        background-color: $state-done;
    }
}

td.state {
    padding-left: 0;
    white-space: nowrap;
}
