.content {
    transition: margin-left .3s ease-in-out;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include media-breakpoint-down(lg) {
        padding-bottom: 5rem;
    }

    @include media-breakpoint-up(lg) {
        margin-left: $main-sidebar-width;
    }

    > .row {
        width: calc(var(--bs-gutter-x) + 100%);

        @include media-breakpoint-up(lg) {
            flex: 1 1 auto;
        }
    }
}

@import "tables";
@import "tabs";
@import "content-card";
@import "context-tree";
@import "states";
