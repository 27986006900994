.main-sidebar {
    transition: width .3s ease-in-out;
    z-index: 110;
    display: none;
    position: initial;
    width: 100%;

    @include media-breakpoint-up(lg) {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        min-height: 100vh;
        width: $main-sidebar-width;
    }

    .hidden-when-collapsed {
        transition: visibility .3s ease, opacity .3s ease;
        opacity: 1;
        display: inline-block;
    }

    .visible-when-collapsed {
        visibility: hidden;
    }

    .logo {
        width: px-rem(90);
        height: auto;
        margin: 0 auto;
    }

    .main-sidebar-toggler {
        color: $gray-500;
        font-size: px-rem(24);
        margin-right: 1rem;

        &::before {
            @include ti-icon(\e64a);
        }
    }

    .main-sidebar-nav {
        font-family: $font-family-medium;
        margin: 0;
        padding: 0;
        width: 100%;

        @include media-breakpoint-up(lg) {
            margin-top: 2rem;
        }

        a {
            color: $gray-500;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            display: block;
            padding: .5rem;
            text-decoration: none;
            white-space: nowrap;

            .ti,
            .icon-spacer {
                display: inline-block;
                width: 2rem;
                text-align: center;
            }
        }

        a:hover,
        a:focus,
        li.active > a {
            background-color: $gray-600;
            border-left: 5px solid #1C64C4;
            color: $white;
            text-decoration: none;
        }

        .main-sidebar-sub-nav {
            margin: 0;
            padding: 0;
            display: none;

            &.open {
                display: block;
            }

            li a {
                color: $white;
            }

            li a:hover,
            li a:focus,
            li.active a {
                border-left: 5px solid $gray-100;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .main-sidebar-open {
        .main-sidebar {
            display: block;
        }
    }
}

@include media-breakpoint-up(lg) {
    .main-sidebar-collapsed {
        .main-navbar,
        .main-header,
        .content {
            margin-left: $main-sidebar-width-collapsed;
        }

        .main-sidebar {
            width: $main-sidebar-width-collapsed;

            .main-sidebar-toggler {
                margin-right: 0;

                &::before {
                    @include ti-icon(\e649);
                }
            }

            .hidden-when-collapsed {
                margin: 0;
                width: 0;
                opacity: 0;
                visibility: hidden;
            }

            .visible-when-collapsed {
                visibility: visible;
            }
        }
    }
}
