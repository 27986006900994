.nav-tabs {
    font-family: $font-family-medium;
    border-bottom: 0;

    .nav-link {
        border: 0;
        border-bottom: 2px solid transparent;
        color: $gray-500;
        margin-bottom: 0;
    }

    .nav-link:hover,
    .nav-link.active {
        border-bottom-color: #4a89dc;
        background-color: unset;
        color: $black;
    }
}

app-wizard {
    .nav-tabs {
        cursor: default;
        .nav-link:hover {
            border-bottom-color: transparent;
            color: #a2a2a2;
        }
        .nav-link.active {
            border-bottom-color: #4a89dc;
            background-color: unset;
            color: #000;
        }
    }
    #context-tree-toggler {
        display: none !important;
    }
}

/* scrollable */
.nav-tabs-scrollable {
    overflow-x: hidden;
    flex-wrap: nowrap;
    margin-right: 1rem;
}

.scrtabs-tab-container,
.scrtabs-tabs-fixed-container,
.scrtabs-tab-scroll-arrow {
    height: 40px;
}

.scrtabs-disable {
    display: none !important;
}

.scrtabs-tab-scroll-arrow {
    border: 0;
}

.scrtabs-tab-scroll-arrow:hover {
    background: transparent;
}
