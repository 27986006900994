/* Settings */
$enable-negative-margins: true;

/* Colors */
$white: #fff;
$gray-100: #f0f0f0;
$gray-200: #fcfcfc;
$gray-300: #e1e0de;
$gray-400: #c1c1c1;
$gray-500: #a2a2a2;
$gray-600: #656565;
$gray-700: #5c5c5c;
$gray-800: #505050;
$gray-900: #4a4a4a;

$primary: #e10020;
$secondary: #4a89dc;

/* Font */
$font-family-sans-serif: $font-family-light;
$headings-font-family: $font-family-light;
$font-size-base: px-rem(15);

/* Headings */
$h1-font-size: px-rem(40);

/* Links */
$link-decoration: none;
$link-hover-decoration: underline;

/* Buttons */
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

/* Navbar */
$navbar-light-toggler-border-color: transparent;

/* Tables */
$table-cell-padding-x: 1rem;
$table-cell-padding-y: 1rem;
$table-border-color: $gray-100;
$table-th-font-weight: normal;

/* Forms */
$input-border-color: $gray-300;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;
$input-placeholder-color: $gray-500;
$form-select-focus-box-shadow: none;
$legend-font-size: px-rem(11);
$legend-margin-bottom: 0;

/* Modals */
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;

/* Shadow */
$box-shadow: 0 px-rem(3) px-rem(6) rgba(0, 0, 0, .16);

/* Border Radius */
$border-radius: 1px;
$border-radius-sm: 1px;
$border-radius-lg: 1px;

/* Paginierung */
$pagination-color: $gray-900;
$pagination-border-color: $gray-300;
$pagination-active-bg: $secondary;
$pagination-active-color: $white;
$pagination-focus-color: $secondary;
$pagination-hover-color: $secondary;
$pagination-focus-box-shadow: none;

/* Tooltip */
$tooltip-font-size: px-rem(15);
$tooltip-max-width: 100vh;
$tooltip-color: $white;
$tooltip-bg: $gray-900;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: .9;
$tooltip-margin: 0;

$tooltip-arrow-width: .8rem;
$tooltip-arrow-height: .4rem;
$tooltip-arrow-color: $gray-900;

/* Utilities: overwrite idth and add 33% and 66% */
$utilities: ("width": (property: width,
        class: w,
        responsive: true,
        values: (25: 25%,
            33: calc(100% / 3),
            50: 50%,
            66: calc(200% / 3),
            75: 75%,
            100: 100%,
            auto: auto)),
);

@media (max-width: 991px) {
    $tooltip-max-width: none;
}
