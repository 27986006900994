$font-family-regular: 'Roboto', sans-serif;
$font-family-light: 'Roboto Light', sans-serif;
$font-family-medium: 'Roboto Medium', sans-serif;
$font-family-icons: 'themify-icons', 'Roboto Light', sans-serif;

$main-sidebar-width: px-rem(240);
$main-sidebar-width-collapsed: px-rem(60);

$context-tree-width: px-rem(240);
$context-tree-width-collapsed: px-rem(60);
